<template>
  <div id="nav">
       <router-view/>
  </div>
</template>

<style >
@import './assets/css/style.css';
body{
  margin: 0;
  font-family: 'Urbanist', sans-serif;
}
</style>
